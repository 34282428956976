import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import { File } from "../Svg/file";

function News() {
  return (
    <div id="news" className="news section">
      <Helmet>
        <meta charSet="utf-8" />

        <title>News | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org/news" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org/news" />
        <meta property="og:title" content="News | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1>News</h1>

            <article className="panel is-primary">
              <div className="panel-heading flex ai-center ">
                Unveiling of VOARS London Declaration Facilities
                <a
                  href="/pdf/VOARS London Declaration Facilities Unveiling Press Release.pdf"
                  className="right"
                  target="_blank"
                  title="Press Release: FGN Unveils its VOARS London Declaration Facilities"
                >
                  <File width={36} height={36} fill="#9E0C0C" /> Press Release
                </a>
              </div>

              <div className="panel-block">
                <p>
                  On 26 July 2021, VOARS London Declaration Facilities was unveiled at an “invitations only” event for banks, trust companies, assets managers, lawyers, accountants and other
                  professionals. Keynote Speakers included His Excellency Abubakar Malami, SAN the Attorney General and Minister of Justice for Nigeria and Her Excellency Zainab Ahmed, Minister of
                  Finance for Nigeria.
                </p>

                <p>
                  <strong>Keynote Address of the Attorney General and Minister of Justice of Nigeria, His Excellency Abubakar Malami, SAN</strong> who is the originating and regulatory authority of
                  VOARS Nigeria pursuant to Articles 1 and 14 of VOARS enabling law
                </p>

                <div className="video">
                  <ReactPlayer url={`${process.env.PUBLIC_URL}/videos/fac45b06-5063-41a7-8733-322b7feeef2b.MP4`} controls={true} />
                </div>

                <p className="video-information">
                  <strong>Keynote address by Her Excellency, Mrs. Zainab Ahmed, Minister of Finance, Nigeria</strong> who directs and oversees VOARS Infrastructure Account, VOARS Escrow Accounts into
                  which all levies are paid; as well as decisions concerning FGN investment in the private sector led Nigeria Essential Infrastructure Fund (NEIF) pursuant to Articles 10 and 15 of
                  VOARS enabling law.
                </p>

                <div className="video">
                  <ReactPlayer url={`${process.env.PUBLIC_URL}/videos/VIDEO-2021-07-27-09-43-46.mp4`} controls={true} />
                </div>

                <p className="video-information">
                  <strong>Presentation by one of the Keynote Speakers Philip Marcovici.</strong>
                </p>

                <div className="video">
                  <ReactPlayer url={`${process.env.PUBLIC_URL}/videos/presentation-philip-marcovici-540.mp4`} controls={true} />
                </div>
                <p className="video-information">
                  Philip Marcovici was one of the architects of Liechtenstein Disclosure Facility through which UK Government recovered more than 1.2 billion British Pounds in Liechtenstein. He is an
                  Adviser to VOARFS Consortium and cooperates with Noel Okorougo, the architect of VOARS, on non-prosecution agreements and non-conviction based assets regularization pursuant to
                  Special Clearance provisions of the Scheme. Admitted to practice in Hong Kong, the State of New York, England and Wales and the Province of British Columbia in Canada (retired),
                  Philip was a partner in the law firm Baker & McKenzie and practiced in Hong Kong and Zurich until retirement in 2009.{" "}
                </p>
              </div>
            </article>

            <article className="panel is-primary">
              <p className="panel-heading">VOARS Abuja Unveiling</p>

              <div className="panel-block">
                <p>On 2 April 2019, VOARS was unveiled in Nigeria to a cross section of stakeholders and professionals. The event held at Transcorp Hilton Hotel, Abuja was well attended.</p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
