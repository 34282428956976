import { Helmet } from "react-helmet";

function About() {
  return (
    <div id="about" className="about section">
      <Helmet>
        <meta charSet="utf-8" />

        <title>About | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org/about" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org/about" />
        <meta property="og:title" content="About | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1>About</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
