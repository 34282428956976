import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Faq from "./Pages/Faq";
import Home from "./Pages/Home";
import News from "./Pages/News";
import PressReleases from "./Pages/PressReleases";
import Regulations from "./Pages/Regulations";
import "./styles/App.scss";

function App() {
  const [isRegulationsModalOpen, setIsRegulationsModalOpen] = useState<boolean>(false);

  const handleRegulationsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsRegulationsModalOpen(true);
  };

  const handleRegulationsModalCloseClick = () => {
    setIsRegulationsModalOpen(false);
  };

  return (
    <Router>
      <div className="App">
        <Header regulationsClicked={handleRegulationsClick} />
        <Switch>
          <Route exact path="/">
            <Home regulationsClicked={handleRegulationsClick} />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/regulations">
            <Regulations />
          </Route>
          <Route path="/news">
            <News />
          </Route>
          <Route path="/press-releases">
            <PressReleases />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/faq">
            <Faq />
          </Route>
        </Switch>
        <Footer />

        <div className={`modal ${isRegulationsModalOpen ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Regulations</p>
              <button className="delete" aria-label="close" onClick={handleRegulationsModalCloseClick}></button>
            </header>
            <section className="modal-card-body">
              <strong>VOARS Special Clearance and Non-Prosecution Agreements Implementation and Management Regulations, 2021</strong> is available at VOARS Declaration Facilities after registration.
            </section>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
