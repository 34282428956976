import { Helmet } from "react-helmet";
import { Download } from "../Svg/download";
import bg from "./bg.jpg";

const Home = ({ regulationsClicked }) => {
  return (
    <div id="about" className="bg-with-text home" style={{ backgroundImage: `url(${bg})` }}>
      <Helmet>
        <meta charSet="utf-8" />

        <title>Voluntary Offshore Assets Regularization Scheme | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org" />
        <meta property="og:title" content="Voluntary Offshore Assets Regularization Scheme | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="overlay flex ai-center">
        <div className="container section">
          <div className="columns">
            <div className="column">
              <p className="green text-shadow">
                VOARS is an opportunity for Nigerian relevant persons and their intermediaries to voluntarily and conveniently regularize their offshore assets held anywhere in the world by paying a
                one-time levy as fine for the years of irregularity.
              </p>
              <p className="green text-shadow">
                A major benefit for declarants include permanent waiver of prosecution for offences related to the offshore assets voluntarily declared. Declarations may be made by relevant persons or
                their intermediaries upon presentation of valid power of attorney. Declarations of offshore assets held anywhere in the world may be made at any of the declaration facilities in Abuja,
                Dubai or London.
              </p>
              <p className="green text-shadow">
                The Scheme was established pursuant to Presidential Executive Order 008 for Voluntary Offshore Assets Regularization Scheme (VOARS) 2018 Amendment 2019. All proceeds are transparently
                invested in infrastructure development in Nigeria through Nigeria Essential Infrastructure Fund (NEIF).
              </p>
              <div className="columns">
                <div className="column">
                  <div className="field has-addons">
                    <a href="/pdf/Gazette_No.198_Vol.106_VOARS_Presidential_Executive_Order_008_Amendment_2019_A4.pdf" className="button" target="_blank">
                      <span className="icon is-large">
                        <Download width={48} height={48} />
                      </span>
                      <span>Download Executive Order</span>
                    </a>
                  </div>
                </div>
                <div className="column">
                  <div className="field has-addons">
                    <a href="#regulations" className="button" onClick={regulationsClicked}>
                      <span className="icon is-large">
                        <Download width={48} height={48} />
                      </span>
                      <span>Download Regulations</span>
                    </a>
                  </div>
                </div>
                <div className="column">
                  <div className="field has-addons">
                    <a href="/pdf/VOARS London Declaration Facilities Unveiling Press Release.pdf" className="button reverse long-text" target="_blank">
                      <span className="icon is-large">
                        <Download width={48} height={48} />
                      </span>
                      <div>
                        <span className="new-badge">New</span>

                        <span>Press Release: FGN Unveils its VOARS London Declaration Facilities</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
