import React, { MouseEvent, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Close } from "../../Svg/close";
import logoWhite from "./logo-white.png";
import logo from "./logo.png";

function Menu({ linkClicked, regulationsClicked }) {
  let location = useLocation();
  return (
    <>
      <div id="menu-nav" className="navbar-menu">
        <Link to="/" className={`navbar-item ${location.pathname === "/about" ? "is-active" : ""}`} onClick={linkClicked}>
          About
        </Link>
        <a href="/pdf/Gazette_No.198_Vol.106_VOARS_Presidential_Executive_Order_008_Amendment_2019_A4.pdf" className="navbar-item" target="_blank">
          Executive Order
        </a>
        <a href="#regulations" className="navbar-item" onClick={regulationsClicked}>
          Regulations
        </a>
        <Link to="/news" className={`navbar-item ${location.pathname === "/news" ? "is-active" : ""}`} onClick={linkClicked}>
          News
        </Link>
        {/* <Link to="/press-releases" className={`navbar-item ${location.pathname === "/press-releases" ? "is-active" : ""}`} onClick={linkClicked}>
          Press Releases
        </Link> */}
        <a href="/pdf/VOARS London Declaration Facilities Unveiling Press Release.pdf" className="navbar-item" target="_blank">
          Press Releases
        </a>
        <Link to="/faq" className={`navbar-item ${location.pathname === "/faq" ? "is-active" : ""}`} onClick={linkClicked}>
          FAQ
        </Link>
        <Link to="/contact" className={`navbar-item ${location.pathname === "/contact" ? "is-active" : ""}`} onClick={linkClicked}>
          Contact
        </Link>
      </div>
    </>
  );
}

function LogoTitle() {
  let location = useLocation();
  if (location.pathname === "/" || location.pathname === "") {
    return (
      <h1>
        Voluntary Offshore Assets Regularization Scheme
        <br />
        of Federal Republic of Nigeria
      </h1>
    );
  }
  return (
    <h2>
      Voluntary Offshore Assets Regularization Scheme
      <br />
      of Federal Republic of Nigeria
    </h2>
  );
}

const Header = ({ regulationsClicked }) => {
  let headerHeight = 0;

  const [isSticky, setIsSticky] = React.useState<boolean>(false);

  const [isMenuMobileOpen, setisMenuMobileOpen] = React.useState<boolean>(false);

  const handleClickMenuBurger = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setisMenuMobileOpen(!isMenuMobileOpen);
  };

  const handleClickMenuItem = () => {
    setisMenuMobileOpen(false);
  };

  const handleScroll = (event: Event) => {
    if (window.pageYOffset < headerHeight) {
      setIsSticky(false);
    }
    if (window.pageYOffset >= headerHeight) {
      setIsSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const header = document.getElementById("header");
    if (header) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      headerHeight = header.offsetHeight;
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header id="header">
        <div className="header-container">
          <div className="container">
            <div className="columns is-mobile is-centered">
              <div className="column is-half-desktop">
                <div className="flex flex-column ai-center logo-and-title flex-center">
                  <a href="/">
                    <img src={logo} className="App-logo" alt="VOARS" width="200px" height="200px" />
                  </a>
                  <LogoTitle />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <nav className={`nav-bar ${isSticky ? "stick" : ""} `}>
        <div className="flex flex-row ai-center is-hidden-touch">
          <a className="logo" href="/">
            <img src={logoWhite} alt="VOARS" width="60px" height="60px" />
          </a>
          <Menu linkClicked={() => {}} regulationsClicked={regulationsClicked} />
        </div>
        <div className="menu-mobile is-hidden-desktop">
          <div className="flex flex-row ai-center flex-end menu-phone">
            <a className="logo" href="/">
              <img src={logo} alt="logo" width="60px" height="60px" />
            </a>
            <a href="#menu" role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="menu-nav" onClick={handleClickMenuBurger}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
        </div>
      </nav>

      {isMenuMobileOpen && (
        <div id="menu-nav" className="navbar-menu navbar-menu-mobile is-active is-hidden-desktop">
          <a className="logo" href="/">
            <img src={logo} alt="logo" width="60px" height="60px" />
          </a>
          <a href="#close" onClick={handleClickMenuBurger} className="close">
            <Close />
          </a>
          <Menu linkClicked={handleClickMenuItem} regulationsClicked={regulationsClicked} />
        </div>
      )}
    </>
  );
};

export default Header;
