import { Helmet } from "react-helmet";

function Regulations() {
  return (
    <div id="regulations" className="regulations section">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Regulations | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org/regulations" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org/regulations" />
        <meta property="og:title" content="Regulations | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1>Regulations</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Regulations;
