import React from "react";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="columns">
          <div className="column text-center">© VOARS 2021</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
