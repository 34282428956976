import React from "react";
import { Helmet } from "react-helmet";
import { Phone } from "../Svg/phone";

function Contact() {
  return (
    <div id="contact" className="contact section">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Contact | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org/contact" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org/contact" />
        <meta property="og:title" content="Contact | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="container">
        <h1>Contact</h1>
        <div className="columns">
          <div className="column">
            <h5>VOARS Declarations Unit</h5>
            <p>
              <span>Office of Attorney General</span>
              <span>Federal Ministry of Justice</span>
              <span>Abuja, Nigeria</span>
            </p>
          </div>
          <div className="column">
            <h5>VOARS London Declaration Facility</h5>
            <p>
              <span>1 Abermale Street, London W1S 4HA</span>
              <span>United Kingdom</span>
            </p>

            <p className="phone-number">
              <a href="tel:+442045266171">
                <Phone />
                +44 20 4526 6171
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
