import { Helmet } from "react-helmet";
import { Download } from "../Svg/download";

function PressReleases() {
  return (
    <div id="press-releases" className="press-releases section">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Press Releases | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org/news" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org/news" />
        <meta property="og:title" content="Press Releases | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1>Press Releases</h1>
            <article className="panel is-primary">
              <p className="panel-heading">Press release 27 July 2021</p>

              <div className="panel-block">
                <div className="field has-addons">
                  <p>
                    <a href="/pdf/VOARS London Declaration Facilities Unveiling Press Release.pdf" className="button" target="_blank">
                      <span className="icon is-large">
                        <Download width={48} height={48} />
                      </span>
                      <span>Download Press Release 27 July 2021</span>
                    </a>
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PressReleases;
