import React from "react";
import { Helmet } from "react-helmet";

function Faq() {
  return (
    <div id="faq" className="faq section">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Frequently Asked Questions | VOARS</title>
        <link rel="canonical" href="https://www.voars-nigeria.org/faq" />
        <meta name="description" content="VOARS, the Voluntary Offshore Assets Regularization Scheme of Nigeria" />

        <meta property="og:url" content="https://www.voars-nigeria.org/faq" />
        <meta property="og:title" content="Frequently Asked Questions | VOARS" />
        <meta property="og:description" content="Voluntary Offshore Assets Regularization Facility of Nigeria" />
      </Helmet>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h1>Frequently Asked Questions</h1>
            <article className="panel is-primary">
              <p className="panel-heading">What period is covered for declarations under the Scheme?</p>

              <div className="panel-block">
                <p>
                  VOARS is an opportunity for “relevant persons” as defined in the Executive Order to voluntarily declare and regularize their “offshore assets” acquired during the preceding thirty
                  years from 1987. That is, 1987 to 2017 for declarations made in 2018; 1988 to 2018 for declarations made in 2019; 1989 to 2019 for declarations made in 2020; 1990 to 2020 for
                  declarations made in 2021 and so on.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">Who can make a declaration?</p>

              <div className="panel-block">
                <p>
                  A voluntary declaration can be made by the declarant or by authorized intermediaries such as custodian banks, lawyers or trustees who must present a valid original Power of Attorney
                  signed by the declarant.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">Is the 35% or any percentage levy a tax?</p>

              <div className="panel-block">
                <p>No, the 35% levy or any percentage levy paid to FGN in Special Clearance by declarants are not taxes ; levies are fines for concealment of undeclared offshore assets.</p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">Will the declarations and documents provided by Declarants under the Scheme be kept confidential?</p>

              <div className="panel-block">
                <p>Yes, all information provided by Declarants under the Scheme will be treated with utmost confidentiality in accordance with the provisions of the relevant laws.</p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">What will happen after the Declarant has made a valid declaration and paid the relevant levy and facility access fee on the undeclared offshore assets?</p>

              <div className="panel-block">
                <p>
                  The Declarant will be issued with an Offshore Assets Regularization Compliance Certificate on the declared and regularized offshore assets which renders the residual offshore assets
                  totally free and available to be used or invested by the Declarant anywhere in the world.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">Do Declarants need to provide identification?</p>

              <div className="panel-block">
                <p>
                  Yes, all Declarants and their intermediaries are required to provide identification that must be certified as true copies by regulated professionals (such as solicitor, barrister or
                  accountant).
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">How should a declarant register for the Scheme in London?</p>

              <div className="panel-block">
                <p>
                  By calling this London number to register for the Scheme <a href="tel:+442045266171">+44 20 4526 6171</a>. The call will be answered by a VOARFS receptionist at the London facility
                  hosted by K2 Integrity.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">What information should be provided on the initial phone call?</p>

              <div className="panel-block">
                <p>
                  The name and contact phone numbers of the declarant and the name and contact phone numbers for their intermediary, should they elect to use one, will be required during the initial
                  phone call.
                </p>
                <p>
                  A declarant or intermediary may elect to make an appointment for a face-to-face meeting at the facility. In this case, they will be given the relevant address in London, Dubai or
                  Abuja.
                </p>
              </div>
            </article>

            <article className="panel is-primary">
              <p className="panel-heading">What happens after the initial phone call?</p>

              <div className="panel-block">
                <p>A VOARS Declaration K2 Integrity Officer will contact the declarant or their intermediary on the phone numbers provided within 3 working days to schedule a face-to-face meeting.</p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">What will happen at the face-to-face meeting?</p>

              <div className="panel-block">
                <p>
                  At the meeting, an electronic Registration Form will be completed. The declarant will be assigned a <strong>Unique Declaration Code (UDC)</strong> which will be their identifier
                  during the process until completion. Depending on the contents of their Registration Form, a tailored <strong>Offshore Assets Voluntary Disclosure Pack (OAVDP)</strong> will be given
                  to them. The declarant will be required to return their completed <strong>Offshore Assets Voluntary Disclosure Pack (OAVDP)</strong> within 7 days together with supporting documents
                  to enable the compliance verification procedure.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">Will there be need for further interviews or documentation?</p>

              <div className="panel-block">
                <p>
                  A VOARS Declaration K2 Integrity Officer will review the completed <strong>OAVDP</strong> and may request further information to be supplied for clarification purposes once the
                  compliance process begins.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">What will Compliance Procedures entail?</p>

              <div className="panel-block">
                <p>
                  To verify eligibility of offshore assets presented for declaration through VOARS, K2 Integrity shall carry out diligent review of documents, public records research and information
                  provided by declarant’s custodian banks, legal counsels, and other intermediaries.
                </p>
              </div>
            </article>

            <article className="panel is-primary">
              <p className="panel-heading">What happens after the Compliance Process?</p>

              <div className="panel-block">
                <p>
                  After the compliance process, an <strong>Eligibility Certificate</strong> will be issued to the Declarant. The certificate and notice will detail the verified offshore assets
                  eligible for declaration under the Scheme, and enables the declarant to move to the next stage – payment of the facility access fee and relevant FGN levy into the escrow account
                  provided on the notice. It will be accompanied by payment instructions.
                </p>
              </div>
            </article>

            <article className="panel is-primary">
              <p className="panel-heading">What happens next?</p>

              <div className="panel-block">
                <p>
                  Upon confirmation of the payments, an <strong>Offshore Assets Regularization Compliance Certificate</strong> will be issued to the declarant. It will show the same details as the
                  offshore assets on the Eligibility Certificate.
                </p>
              </div>
            </article>

            <article className="panel is-primary">
              <p className="panel-heading">Currently VOARS Scheme ends on 8 April 2022, is there a plan to extend it?</p>

              <div className="panel-block">
                <p>
                  A very good reason for Declarants to move quickly. Federal Government of Nigeria guarantees that the window will be extended for all those whose declarations are in process before
                  the deadline. Also, all who have registered for declaration before the deadline will be accorded extensions of time in order to complete their declaration.
                </p>
              </div>
            </article>

            <article className="panel is-primary">
              <p className="panel-heading">What safeguards are there that recovered assets received by government through VOARS will benefit the people of Nigeria?</p>

              <div className="panel-block">
                <p>
                  The Presidential Executive Order that governs VOARS stipulates that all recoveries will be used for essential infrastructure development in Nigeria. For this reason VOARS Nigeria has
                  been designed with an inbuilt <strong>Nigeria Essential Infrastructure Fund (NEIF)</strong> which will receive the recoveries for infrastructure development. Declarants are invited
                  to join government in Nigeria infrastructure development by investing portions of their residual assets in NEIF which promises high returns.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">
                What is the process for application and processing of non-prosecution agreements by custodian banks, trust companies, asset managers and other intermediaries?
              </p>

              <div className="panel-block">
                <p>
                  The process begins with a written application by the custodian bank, asset manager, trust company etc delivered to the declaration facility in Abuja, London or Dubai. Upon receipt of
                  the application, and during the initial meeting, a template will be provided and this will be the basis for the applicant and Federal Government of Nigeria to cordially negotiate and
                  sign a non-prosecution agreement with specific conditions.
                </p>
              </div>
            </article>
            <article className="panel is-primary">
              <p className="panel-heading">Can declarations be made by email? </p>

              <div className="panel-block">
                <p>
                  For the highest assurance on data protection and confidentiality of Declarants information, no declarations or correspondence will be by email. At the meeting and initial
                  registration, the Declarant or their intermediary with valid notarized power of attorney will be given details and templates for declarations; and timelines for return of documents
                  and processing.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
